

import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Navbar from './component/navbar/navbar';
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import ProtectedRoute from "./protectedRoute/protectedRoute"; // Import the protected route

// Import other components
import EmployeePage from './pages/employeePage/employeePage';
import LoginPage from './component/login/login';
import AdminPage from './component/admin/admin';
import HomePage from './pages/home/home';
import AddUser from "./component/users/AddUser";
import EmployeeList from "./component/employeeList/allEmployee";
import EditUser from "./component/users/EditUser";
import ViewUser from "./component/users/ViewUser";
import AdminRegister from "./component/register/register";
import UpdateAdmin from "./component/register/updateAdmin";
import Sidebar from './component/sidebar/sidebar';
import SalePage from './pages/salePage/salePage';
import AddSale from './component/sale/addSale';
import SaleList from './component/sale/viewSales';
import PayrollPage from './pages/payrollPage/payrollPage';
import AddPayroll from './component/payroll/addPayroll';
import PayrollList from './component/payroll/viewPayroll';
import UpcomingFeaturePage from './component/modals/upComingFeature';
import EditPayroll from './component/payroll/editPayroll';
import EditSale from './component/sale/editSale';

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Navbar />
        <Sidebar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register-admin" element={<AdminRegister />} />
          <Route path="/update-admin" element={<UpdateAdmin />} />


          {/* Protected routes */}
          <Route path="/admin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
          <Route path="/employee" element={<ProtectedRoute><EmployeePage /></ProtectedRoute>} />
          <Route path="/sale" element={<ProtectedRoute><SalePage /></ProtectedRoute>} />
          <Route path="/payroll" element={<ProtectedRoute><PayrollPage /></ProtectedRoute>} />
          <Route path="/upcoming-feature" element={<ProtectedRoute><UpcomingFeaturePage /></ProtectedRoute>} />

          <Route exact path="/addEmployee" element={<ProtectedRoute><AddUser /></ProtectedRoute>} />
          <Route exact path="/employeeList" element={<ProtectedRoute><EmployeeList /></ProtectedRoute>} />
          <Route exact path="/edituser/:id" element={<ProtectedRoute><EditUser /></ProtectedRoute>} />
          <Route exact path="/viewuser/:id" element={<ProtectedRoute><ViewUser /></ProtectedRoute>} />

          <Route exact path="/addPayroll" element={<ProtectedRoute><AddPayroll /></ProtectedRoute>} />
          <Route exact path="/payroll-list" element={<ProtectedRoute><PayrollList /></ProtectedRoute>} />
          <Route exact path="/editpayroll/:id" element={<ProtectedRoute><EditPayroll /></ProtectedRoute>} />
          <Route exact path="/viewPayroll/:id" element={<ProtectedRoute><ViewUser /></ProtectedRoute>} />

          <Route exact path="/addSale" element={<ProtectedRoute><AddSale /></ProtectedRoute>} />
          <Route exact path="/sale-list" element={<ProtectedRoute><SaleList /></ProtectedRoute>} />
          <Route exact path="/editsale/:id" element={<ProtectedRoute><EditSale /></ProtectedRoute>} />
          <Route exact path="/viewsale/:id" element={<ProtectedRoute><ViewUser /></ProtectedRoute>} />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
