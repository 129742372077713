import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./PayrollList.css";
import apiRequest from "../../lib/apiRequest";
import { Link, useNavigate } from "react-router-dom"; // For navigation (edit, add)
import DeleteConfirmationModal from "../modals/DeleteConfirmationModal"; // Import the modal

export default function PayrollList() {
  const [showModal, setShowModal] = useState(false);
  const [currentPayroll, setCurrentPayroll] = useState(null);

  const [payrolls, setPayrolls] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPayrolls = async () => {
      const response = await apiRequest.get("/payroll/all");
      //const response = await axios.get("http://localhost:8080/payroll/all");
      setPayrolls(response.data);
    };
    fetchPayrolls();
  }, []);

  // Function to format the current date
  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  // Function to generate PDF
  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;

    // Get current date and format it
    const currentDate = formatDate(new Date());

    // Add the print date to the top left
    doc.setFontSize(11);
    doc.text(`${currentDate}`, 14, 10);

    // Center the title
    const title = "Employee Payrolls";
    const textWidth = doc.getTextWidth(title);
    doc.setFontSize(18);
    doc.text(title, (pageWidth - textWidth) / 2, 22);

    doc.setFontSize(11);
    doc.setTextColor(100);

    const headers = [
      [
        "Employee ID",
        "Name",
        "Base Salary",
        "Bonus",
        "Deductions",
        "Total Salary",
        "Payroll Date",
      ],
    ];

    const data = payrolls.map((payroll) => {
      const totalSalary =
        payroll.baseSalary + payroll.bonus - payroll.deductions;
      return [
        payroll.user ? payroll.user.employeeId : "N/A",
        payroll.user ? payroll.user.name : "Unknown",
        payroll.baseSalary,
        payroll.bonus,
        payroll.deductions,
        totalSalary,
        payroll.payrollDate,
      ];
    });

    // Generate table with grid theme
    doc.autoTable({
      head: headers,
      body: data,
      theme: "grid",
      margin: { top: 30 },
      startY: 30, // Start the table after the title
      styles: { halign: "center" }, // Center-align the content in table cells
    });

    // Open PDF in a new window without automatically triggering the print dialog
    window.open(doc.output("bloburl"), "_blank");
  };

  const handleDelete = async () => {
    try {
      // await axios.delete(
      await apiRequest.delete(`/sales/delete/${currentPayroll.id}`);
      setPayrolls(payrolls.filter((sale) => sale.id !== currentPayroll.id)); // Remove deleted sale from list
      setShowModal(false); // Close modal
    } catch (error) {
      console.error("Error deleting sale", error);
    }
  };
  // Open delete confirmation modal
  const openDeleteModal = (sale) => {
    setCurrentPayroll(sale);
    setShowModal(true);
  };

  // Close delete confirmation modal
  const closeDeleteModal = () => {
    setShowModal(false);
  };

  // Handle Add Payroll
  const handleAdd = () => {
    navigate("/addPayroll"); // Navigate to add payroll page
  };

  return (
    <div className="container my-4">
      <h2 className="text-center">Employee Payrolls</h2>
      <button
        className="btn btn-primary mb-3"
        style={{ marginRight: "20px" }}
        onClick={generatePDF}
      >
        Print Payrolls
      </button>
      <button className="btn btn-success mb-3 ml-7" onClick={handleAdd}>
        Add Payroll
      </button>
      <div className="table-responsive">
        <table className="table table-striped table-bordered shadow">
          <thead className="thead-dark">
            <tr>
              <th>Employee ID</th>
              <th>Name</th>
              <th>Base Salary</th>
              <th>Bonus</th>
              <th>Deductions</th>
              <th>Total Salary</th>
              <th>Payroll Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {payrolls.map((payroll) => {
              const totalSalary =
                payroll.baseSalary + payroll.bonus - payroll.deductions;
              return (
                <tr key={payroll.id}>
                  <td>{payroll.user ? payroll.user.employeeId : "N/A"}</td>
                  <td>{payroll.user ? payroll.user.name : "Unknown"}</td>
                  <td>{payroll.baseSalary}</td>
                  <td>{payroll.bonus}</td>
                  <td>{payroll.deductions}</td>
                  <td>{totalSalary}</td>
                  <td>{payroll.payrollDate}</td>
                  <td>
                    <div className="d-flex flex-column flex-md-row justify-content-start">
                      <Link
                        className="btn btn-outline-primary mx-1 mb-2 mb-md-0"
                        to={`/editpayroll/${payroll.id}`}
                      >
                        Edit
                      </Link>
                      <button
                        className="btn btn-danger mx-1"
                        onClick={() => openDeleteModal(payroll)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Delete Confirmation Modal */}
      {currentPayroll && (
        <DeleteConfirmationModal
          show={showModal}
          handleClose={closeDeleteModal}
          handleDelete={handleDelete}
          itemId={currentPayroll.user?.employeeId || "N/A"}
          itemName={currentPayroll.user?.name}
          itemType="Sale"
        />
      )}
    </div>
  );
}
