import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiRequest from "../../lib/apiRequest";

export default function ViewUser() {
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    role: "",
    dateOfBirth: "",
    salary: "",
    ssn: "",
    employeeId: "",
  });

  const { id } = useParams();

  useEffect(() => {
    const loadUser = async () => {
      const result = await apiRequest.get(`/user/${id}`);
      //console.log("this is", result.data);
      setUser(result.data);
    };
    loadUser();
  }, [id]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-md-3 border rounded p-4 mt-2 text-start shadow ">
          <h2 className="text-center m-4">User Details</h2>

          <div className="card">
            <div className="card-header">
              Details of user id: {user.id}
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Name:</b> {user.name}
                </li>
                <li className="list-group-item">
                  <b>Email:</b> {user.email}
                </li>
                <li className="list-group-item">
                  <b>Phone:</b> {user.phone}
                </li>
                <li className="list-group-item">
                  <b>Address:</b> {user.address}
                </li>
                <li className="list-group-item">
                  <b>Role:</b> {user.role}
                </li>
                <li className="list-group-item">
                  <b>Date of Birth:</b> {user.dateOfBirth}
                </li>
                <li className="list-group-item">
                  <b>Salary:</b> {user.salary}
                </li>
                <li className="list-group-item">
                  <b>SSN:</b> {user.ssn}
                </li>
                <li className="list-group-item">
                  <b>Employee ID:</b> {user.employeeId}
                </li>
              </ul>
            </div>
          </div>
          <Link className="btn btn-primary my-2" to={"/employeeList"}>
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
}
