import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import apiRequest from "../../lib/apiRequest";

export default function EditUser() {
  let navigate = useNavigate();
  const { id } = useParams();

  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    role: "USER",
    dateOfBirth: "",
    salary: "",
    ssn: "",
    employeeId: "",
  });

  const {
    name,
    email,
    phone,
    address,
    role,
    dateOfBirth,
    salary,
    ssn,
    employeeId,
  } = user;

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    //await axios.put(`http://localhost:8080/user/${id}`, user);
    await apiRequest.put(`/user/${id}`, user);
    navigate("/employeeList");
  };

  // const loadUser = async () => {
  //   //const result = await axios.get(`http://localhost:8080/user/${id}`);
  //   const result = await apiRequest.get(`/user/${id}`);
  //   setUser(result.data);
  // };

  useEffect(() => {
    const loadUser = async () => {
      //const result = await axios.get(`http://localhost:8080/user/${id}`);
      const result = await apiRequest.get(`/user/${id}`);
      setUser(result.data);
    };
    loadUser();
  }, [id]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2 border rounded p-4 mt-2 text-start shadow ">
          <h2 className="text-center m-4">Edit User</h2>

          <form onSubmit={(e) => onSubmit(e)}>
            <div className="mb-3">
              <label htmlFor="Name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your name"
                name="name"
                value={name || ""}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter your email"
                name="email"
                value={email || ""}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Phone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your phone number"
                name="phone"
                value={phone || ""}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Address" className="form-label">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your address"
                name="address"
                value={address || ""}
                onChange={(e) => onInputChange(e)}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Role" className="form-label">
                Role
              </label>
              <select
                className="form-control"
                name="role"
                value={role || "USER"} // Ensure the selected value reflects the current role
                onChange={onInputChange} // Handle change in role
              >
                {/* Dynamically display the current role as an option */}
                <option value={role}>{role}</option> {/* Current role */}
                {/* Other role options */}
                {role !== "ADMIN" && <option value="ADMIN">Admin</option>}
                {role !== "USER" && <option value="USER">User</option>}
                {role !== "HR" && <option value="HR">HR</option>}
                {role !== "MANAGER" && <option value="MANAGER">Manager</option>}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="DateOfBirth" className="form-label">
                Date of Birth
              </label>
              <input
                type="date"
                className="form-control"
                name="dateOfBirth"
                value={dateOfBirth || ""}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Salary" className="form-label">
                Salary
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter your salary"
                name="salary"
                value={salary || ""}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="SSN" className="form-label">
                SSN
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your SSN"
                name="ssn"
                value={ssn || ""}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="EmployeeID" className="form-label">
                Employee ID
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your Employee ID"
                name="employeeId"
                value={employeeId || ""}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <button type="submit" className="btn btn-outline-primary">
              Submit
            </button>
            <Link className="btn btn-outline-danger mx-2" to="/employee">
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}
