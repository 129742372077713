import React, { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import "chart.js/auto"; // Automatically register necessary chart components
import apiRequest from "../../lib/apiRequest";
import logger from "../../logger";

export default function UserDashboard() {
  //const [employees, setEmployees] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [rolesData, setRolesData] = useState({});
  const [totalSalary, setTotalSalary] = useState(0);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await apiRequest.get("/user/all");
        //const response = await axios.get("http://localhost:8080/users");
        //setEmployees(response.data);
        calculateStats(response.data);
      } catch (error) {
        logger.error("Error fetching employee data", {
          message: error.message,
          stack: error.stack,
        });
      }
    };

    const calculateStats = (employees) => {
      // Total number of employees
      setTotalEmployees(employees.length);

      // Total salary
      const salarySum = employees.reduce(
        (acc, employee) => acc + (employee.salary || 0),
        0
      );
      setTotalSalary(salarySum);

      // Employee count by role
      const roleCounts = employees.reduce((acc, employee) => {
        acc[employee.role] = (acc[employee.role] || 0) + 1;
        return acc;
      }, {});
      setRolesData(roleCounts);
    };

    fetchEmployees();
  }, []);

  const roleChartData = {
    labels: Object.keys(rolesData),
    datasets: [
      {
        label: "Employees by Role",
        data: Object.values(rolesData),
        backgroundColor: ["#3498db", "#2ecc71", "#e74c3c", "#9b59b6"],
      },
    ],
  };

  return (
    <div className="container my-3">
      <h2 className="text-center mb-4">Employee Dashboard</h2>

      <div className="row text-center">
        <div className="col-md-4 mt-3 mt-md-0">
          <div className="card bg-primary text-white shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Total Employees</h5>
              <p className="card-text display-4">{totalEmployees}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3 mt-md-0">
          <div className="card bg-success text-white shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Total Salary</h5>
              <p className="card-text display-4">
                ${totalSalary.toLocaleString()}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3 mt-md-0">
          <div className="card bg-warning text-white shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Average Salary</h5>
              <p className="card-text display-4">
                ${(totalSalary / totalEmployees || 0).toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Charts and role data */}
      <div className="row mt-5">
        <div className="col-md-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="text-center">Employees by Role (Chart)</h5>
              <Bar data={roleChartData} />
            </div>
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="text-center">Employees by Role (List)</h5>
              <ul className="list-group">
                {Object.keys(rolesData).map((role) => (
                  <li key={role} className="list-group-item ">
                    {role}: {rolesData[role]} employee(s)
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
