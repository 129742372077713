import React from "react";
import { Link } from "react-router-dom";

const UpcomingFeaturePage = () => {
  return (
    <div className="upcoming-feature-container">
      <h1>Upcoming Feature!</h1>
      <p>We are excited to announce that a new feature is on the way!</p>
      <p>
        Stay tuned for more updates. We are working hard to improve your
        experience and bring you something awesome.
      </p>

      <p>
        Expected Release Date: <strong>Next Month</strong>
      </p>

      {/* Button to navigate back to the homepage */}
      <Link to="/" className="btn btn-primary">
        Back to Home
      </Link>
    </div>
  );
};

export default UpcomingFeaturePage;
