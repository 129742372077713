import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import apiRequest from "../../lib/apiRequest";

export default function EditSale() {
  let navigate = useNavigate();
  const { id } = useParams(); // Get the sale ID from the URL parameters

  // Initialize sale object with empty user object for employeeId
  const [sale, setSale] = useState({
    agentId: "", // agentId will be set to user.employeeId
    policyId: "",
    salesAmount: "",
    salesDate: "",
    user: { employeeId: "" }, // Ensure user object exists with employeeId
  });

  const { agentId, policyId, salesAmount, salesDate, user } = sale;

  const onInputChange = (e) => {
    setSale({ ...sale, [e.target.name]: e.target.value || "" });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Ensure the user.employeeId is sent as agentId
    const updatedSale = {
      ...sale,
      agentId: user?.employeeId, // Use employeeId as agentId
    };
    // Make sure the policyId is included in the request
    //console.log("Updating sale with policyId:", updatedSale.policyId);

    await apiRequest.put(`/sales/update/${id}`, updatedSale);
    navigate("/sale-list");
  };

  useEffect(() => {
    const loadSale = async () => {
      const result = await apiRequest.get(`/sales/${id}`);
      //console.log("Agent id check update page", result.data);

      // Set the sale object and ensure agentId is mapped to user.employeeId
      setSale({
        ...result.data,
        agentId: result.data.user?.employeeId || "", // Map employeeId to agentId
      });
    };
    loadSale();
  }, [id]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2 border rounded p-4 mt-2 text-start shadow ">
          <h2 className="text-center m-4">Edit Sale</h2>

          <form onSubmit={(e) => onSubmit(e)}>
            <div className="mb-3">
              <label htmlFor="AgentID" className="form-label">
                Agent ID
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Agent ID"
                name="agentId"
                value={agentId || ""}
                onChange={(e) => onInputChange(e)}
                readOnly // Make Agent ID non-editable if needed
              />
            </div>
            <div className="mb-3">
              <label htmlFor="PolicyID" className="form-label">
                Policy ID
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Policy ID"
                name="policyId"
                value={policyId || ""}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="SalesAmount" className="form-label">
                Sales Amount
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Sales Amount"
                name="salesAmount"
                value={salesAmount || ""}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="SalesDate" className="form-label">
                Sales Date
              </label>
              <input
                type="date"
                className="form-control"
                name="salesDate"
                value={salesDate || ""}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <button type="submit" className="btn btn-outline-primary">
              Submit
            </button>
            <Link className="btn btn-outline-success mx-2" to="/sale-list">
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}
