import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { AuthContext } from "../../context/AuthContext"; // Import the context

function Navbar() {
  const navigate = useNavigate();
  const { auth, updateAuth } = useContext(AuthContext); // Get the user and updateAuth function from context
  const [isLoggedIn, setIsLoggedIn] = useState(!!auth.user); // Initialize based on auth.user

  //console.log("authContext from header", updateAuth);
  // Effect to set isLoggedIn based on auth.user
  useEffect(() => {
    setIsLoggedIn(!!auth.user); // Set true if user exists, false if null
  }, [auth.user]); // Run effect when auth.user changes

  // Define the profile image URL
  const profileImage = auth.user?.profileImage
    ? auth.user.profileImage // Use the S3 image if available
    : auth.user?.profileImageUrl
    ? auth.user.profileImageUrl // Otherwise, use the external image if available
    : "/default.png"; // Fallback to a default image if neither is available

  //console.log("profileImage", profileImage);
  // Handle logout: Clear cookies and update the context
  const handleLogout = () => {
    updateAuth(null, null); // Set auth and token to null
    Cookies.remove("user"); // Remove cookies
    Cookies.remove("token");
    navigate("/"); // Redirect to home page after logout
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            KC Management System
          </a>

          <div className="d-flex align-items-center">
            {/* Display Login Status */}
            {/* <p>Is logged in: {isLoggedIn ? "Yes" : "No"}</p> */}

            {/* Conditionally render Login/Logout Button based on user state */}
            {!isLoggedIn ? (
              <Link className="btn btn-outline-light mx-2" to="/login">
                Login
              </Link>
            ) : (
              <button
                className="btn btn-outline-light mx-2"
                onClick={handleLogout}
              >
                Logout
              </button>
            )}

            {/* Conditionally render Profile Image if logged in */}
            {isLoggedIn && (
              <Link to="/update-admin">
                <img
                  src={profileImage}
                  alt="Profile"
                  className="profile-pic"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                />
              </Link>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
