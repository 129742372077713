import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./saleList.css";
import DeleteConfirmationModal from "../modals/DeleteConfirmationModal"; // Import the modal
import apiRequest from "../../lib/apiRequest";
import logger from "../../logger";

export default function SaleList() {
  const [showModal, setShowModal] = useState(false);
  const [currentSale, setCurrentSale] = useState(null);

  const [sales, setSales] = useState([]);
  //const navigate = useNavigate();

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await apiRequest.get("/sales/all");
        //console.log("Full sales data: ", response.data);
        setSales(response.data);
      } catch (error) {
        logger.error("Error fetching sales data:", {
          message: error.message,
          stack: error.stack,
        });
      }
    };

    fetchSales();
  }, []);

  // Function to format the current date
  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  // Function to generate PDF
  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;

    // Get current date and format it
    const currentDate = formatDate(new Date());

    // Add the print date to the top left
    doc.setFontSize(11);
    doc.text(`Print Date: ${currentDate}`, 14, 10);

    // Center the title
    const title = "Sales List";
    const textWidth = doc.getTextWidth(title);
    doc.setFontSize(18);
    doc.text(title, (pageWidth - textWidth) / 2, 22);

    doc.setFontSize(11);
    doc.setTextColor(100);

    const headers = [["Agent ID", "Policy ID", "Sales Amount", "Sales Date"]];

    const data = sales.map((sale) => [
      sale.user?.employeeId || "N/A",
      sale.policyId,
      `$${sale.salesAmount.toFixed(2)}`,
      new Date(sale.salesDate).toLocaleDateString(),
    ]);

    // Generate table with grid theme
    doc.autoTable({
      head: headers,
      body: data,
      theme: "grid",
      margin: { top: 30 },
      startY: 30,
      styles: { halign: "center" },
    });

    // Open PDF in a new window without automatically triggering the print dialog
    window.open(doc.output("bloburl"), "_blank");
  };

  // Handle Delete
  const handleDelete = async () => {
    try {
      await apiRequest.delete(`/sales/delete/${currentSale.id}`);
      setSales(sales.filter((sale) => sale.id !== currentSale.id)); // Remove deleted sale from list
      setShowModal(false); // Close modal
    } catch (error) {
      logger.error("Error deleting sale", {
        message: error.message,
        stack: error.stack,
      });
    }

    // catch (error) {
    //            logger.error("Error fetching employees", {
    //            message: error.message,
    //            stack: error.stack,
    //            });
    //          }
  };

  // Open delete confirmation modal
  const openDeleteModal = (sale) => {
    setCurrentSale(sale);
    setShowModal(true);
  };

  // Close delete confirmation modal
  const closeDeleteModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container my-4">
      <h2 className="text-center">Sales List</h2>
      <button className="btn btn-primary mb-3" onClick={generatePDF}>
        Print Sales List
      </button>
      <div className="table-responsive">
        <table className="table table-bordered table-striped table-hover">
          <thead className="thead-dark">
            <tr>
              <th>Agent ID</th>
              <th>Policy ID</th>
              <th>Sales Amount</th>
              <th>Sales Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sales.map((sale) => (
              <tr key={sale.id}>
                <td>{sale.user?.employeeId || "N/A"}</td>
                <td>{sale.policyId}</td>
                <td>{`$${sale.salesAmount.toFixed(2)}`}</td>
                <td>{new Date(sale.salesDate).toLocaleDateString()}</td>
                <td>
                  <div className="d-flex flex-column flex-md-row justify-content-start">
                    <Link
                      className="btn btn-outline-primary mx-1 mb-2 mb-md-0"
                      to={`/editsale/${sale.id}`}
                    >
                      Edit
                    </Link>
                    <button
                      className="btn btn-danger mx-1"
                      onClick={() => openDeleteModal(sale)}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Delete Confirmation Modal */}
      {currentSale && (
        <DeleteConfirmationModal
          show={showModal}
          handleClose={closeDeleteModal}
          handleDelete={handleDelete}
          itemId={currentSale.user?.employeeId || "N/A"}
          itemName={currentSale.policyId}
          itemType="Sale"
        />
      )}
    </div>
  );
}
