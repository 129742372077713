import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import apiRequest from "../../lib/apiRequest";

export default function AdminEditModal({
  show,
  handleClose,
  admin,
  setAdmins,
  admins,
}) {
  const [adminData, setAdminData] = useState({
    name: "",
    email: "",
    phone: "",
    role: "USER",
    profileImage: null,
  });

  const [previewImage, setPreviewImage] = useState(null); // State for preview
  const defaultImage = "/default.png"; // Default image

  useEffect(() => {
    if (admin) {
      //console.log("AdminEditModal - Admin ID:", admin.id); //
      setAdminData({
        name: admin.name || "",
        email: admin.email || "",
        phone: admin.phone || "",
        role: admin.role || "USER",
        profileImage: admin.profileImage || null,
      });
      setPreviewImage(admin.profileImage || defaultImage);
    }
  }, [admin]);

  const onInputChange = (e) => {
    setAdminData({ ...adminData, [e.target.name]: e.target.value });
  };

  const onImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAdminData({ ...adminData, profileImage: file }); // Update profileImage state
      setPreviewImage(URL.createObjectURL(file)); // Preview the new image
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Step 1: Upload the image if it's a new file
      let imageUrl = adminData.profileImage;

      if (adminData.profileImage instanceof File) {
        const imageFormData = new FormData();
        imageFormData.append("file", adminData.profileImage);

        const imageResponse = await apiRequest.post(
          "/images/upload",
          imageFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (imageResponse.status === 200) {
          imageUrl = imageResponse.data;
        } else {
          throw new Error("Image upload failed");
        }
      }

      // Step 2: Prepare the form data for the update request
      const formData = new FormData();
      formData.append("name", adminData.name);
      formData.append("email", adminData.email);
      formData.append("phone", adminData.phone);
      formData.append("role", adminData.role);
      if (imageUrl) formData.append("profileImageUrl", imageUrl);

      const response = await apiRequest.put(
        `/admin/update/${admin.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const updatedAdmin = response.data;

      // Step 3: Update admin list in the parent component
      setAdmins(
        admins.map((a) => (a.id === updatedAdmin.id ? updatedAdmin : a))
      );

      handleClose(); // Close modal
    } catch (error) {
      console.error("Error updating admin:", error.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="mt-3 text-center">
            <img
              src={previewImage}
              alt="Profile Preview"
              className="rounded-circle"
              width="100"
              height="100"
              style={{ objectFit: "cover" }}
            />
          </div>

          <Form.Group controlId="formProfileImage" className="mt-3">
            <Form.Label>Profile Image</Form.Label>
            <Form.Control type="file" onChange={onImageChange} />
          </Form.Group>

          <Form.Group controlId="formName" className="mt-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              name="name"
              value={adminData.name}
              onChange={onInputChange}
            />
          </Form.Group>

          <Form.Group controlId="formEmail" className="mt-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={adminData.email}
              onChange={onInputChange}
            />
          </Form.Group>

          <Form.Group controlId="formPhone" className="mt-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter phone number"
              name="phone"
              value={adminData.phone}
              onChange={onInputChange}
            />
          </Form.Group>

          <Form.Group controlId="formRole" className="mt-3">
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              name="role"
              value={adminData.role}
              onChange={onInputChange}
            >
              <option value="ADMIN">Admin</option>
              <option value="USER">User</option>
              <option value="HR">HR</option>
              <option value="MANAGER">Manager</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
