import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import AdminAccessModal from "../component/modals/AdminAccessModal";

const ProtectedRoute = ({ children }) => {
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  useEffect(() => {
    // Simulate loading when the app first mounts
    if (auth.user !== null && auth.token !== null) {
      setLoading(false);
    } else if (auth.user === null && auth.token === null) {
      setLoading(false);
    }
  }, [auth.user, auth.token]);

  useEffect(() => {
    // Show modal if user's role is not 'ADMIN'
    if (auth.user && auth.user.role !== "ADMIN") {
      setShowModal(true);
      console.log("ProtectedRoute User Role:", auth.user.role);
    }
  }, [auth.user]);

  // While loading, display a loading message
  if (loading) {
    return <div>Loading...</div>;
  }

  // If no user is logged in, redirect to the login page
  if (!auth.user) {
    return <Navigate to="/login" />;
  }

  // If user's role is not 'ADMIN', display the modal
  if (auth.user.role !== "ADMIN") {
    const handleClose = () => {
      setShowModal(false);
      window.location.href = "/";
    };

    return (
      <AdminAccessModal
        show={showModal}
        handleClose={handleClose} // Close modal and navigate home
      />
    );
  }

  // If authenticated and role is 'ADMIN', render the children (protected content)
  return children;
};

export default ProtectedRoute;
