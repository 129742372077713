import { Link } from "react-router-dom";

import SalesDashboard from "../../component/sale/saleDashboard";

export default function SalePage() {
  return (
    <div className="container">
      <h2 className="text-center my-3">Sale Center</h2>
      <div className="py-4 d-flex justify-content-center gap-3">
        <Link className="btn btn-outline-primary" to="/addSale">
          + Add Sale
        </Link>
        <Link className="btn btn-outline-primary" to="/sale-list">
          🔎 View Sale
        </Link>
      </div>
      <SalesDashboard />
    </div>
  );
}
