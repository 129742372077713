import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DeleteConfirmationModal from "../modals/DeleteConfirmationModal"; // Import the modal
import apiRequest from "../../lib/apiRequest";

export default function Home() {
  const [showModal, setShowModal] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const loadUsers = async () => {
      try {
        //console.log("Fetching data from API...");
        const result = await apiRequest.get("/user/all");
        //console.log("Data fetched:", result.data);
        //const result = await axios.get("http://localhost:8080/users");
        if (isMounted) {
          setUsers(result.data);
          setFilteredUsers(result.data); // Initialize with all users
          setLoading(false);
        }
      } catch (error) {
        //console.error("Error fetching employee data:", error);
        if (isMounted) {
          setError(error);
          setLoading(false);
        }
      }
    };

    loadUsers();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleDelete = async () => {
    try {
      await apiRequest.delete(`/user/delete/${currentEmployee.id}`);

      // Remove the deleted user from both `users` and `filteredUsers` state
      const updatedUsers = users.filter(
        (user) => user.id !== currentEmployee.id
      );
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers); // Update filtered users as well

      setShowModal(false); // Close modal
    } catch (error) {
      console.error("Error deleting user", error);
    }
  };

  // Open delete confirmation modal
  const openDeleteModal = (sale) => {
    setCurrentEmployee(sale);
    setShowModal(true);
  };

  // Close delete confirmation modal
  const closeDeleteModal = () => {
    setShowModal(false);
  };

  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      // Reset filtered users to all users when search is cleared
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(
        (user) =>
          user.employeeId && user.employeeId.toString().includes(searchTerm)
      );
      setFilteredUsers(filtered);
    }
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const handlePrint = () => {
    const doc = new jsPDF("p", "pt", "a4"); // Adjust PDF layout
    const pageWidth = doc.internal.pageSize.width;
    const currentDate = formatDate(new Date());

    // Add print date to the top left
    doc.setFontSize(11);
    doc.text(`Print Date: ${currentDate}`, 40, 30);

    // Add and center the title
    const title = "Employee List";
    doc.setFontSize(18);
    const textWidth =
      (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textOffset = (pageWidth - textWidth) / 2;
    doc.text(title, textOffset, 50);

    // Define table headers and data
    const headers = [
      [
        "S.N",
        "Name",
        "Email",
        "Phone",
        "Address",
        "Role",
        "Date of Birth",
        "Salary",
        "SSN",
        "Employee ID",
      ],
    ];

    const data = filteredUsers.map((user, index) => [
      index + 1,
      user.name,
      user.email,
      user.phone,
      user.address,
      user.role,
      user.dateOfBirth,
      user.salary,
      user.ssn,
      user.employeeId,
    ]);

    // Generate table with page breaks and fit it properly within the page width
    doc.autoTable({
      head: headers,
      body: data,
      theme: "grid",
      margin: { top: 60 }, // Adjust top margin
      styles: {
        halign: "center", // Center align the text inside cells
        fontSize: 8, // Smaller font to fit more content
      },
      headStyles: {
        fillColor: [22, 160, 133], // Optional: Adding a header background color
      },
      columnStyles: {
        0: { cellWidth: 20 }, // S.N.
        1: { cellWidth: 50 }, // Name
        2: { cellWidth: 70 }, // Email
        3: { cellWidth: 60 }, // Phone
        4: { cellWidth: 70 }, // Address
        5: { cellWidth: 50 }, // Role
        6: { cellWidth: 60 }, // Date of Birth
        7: { cellWidth: 50 }, // Salary
        8: { cellWidth: 50 }, // SSN
        9: { cellWidth: 50 }, // Employee ID
      },
      startY: 70, // Ensures that the table starts after the title
      pageBreak: "auto", // Automatically handle page breaks
      tableWidth: "wrap", // Automatically fit table to the page
    });

    // Open PDF in a new window without automatically triggering the print dialog
    window.open(doc.output("bloburl"), "_blank");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading users: {error.message}</div>;
  }

  return (
    <div className="container">
      <div className="row py-4">
        <div className="col-md-6">
          <h2 className="text-center">Employee List</h2>
        </div>
        <div className="col-md-6 d-flex justify-content-end">
          <input
            type="text"
            className="form-control w-50 me-2"
            placeholder="Search by Employee ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="btn btn-outline-primary mx-2"
            onClick={handleSearch}
          >
            Search
          </button>
          <button className="btn btn-outline-success" onClick={handlePrint}>
            Print PDF
          </button>
        </div>
      </div>

      {/* Wrap the table in a div with horizontal scrolling */}
      <div id="printContainer" className="py-4 table-responsive">
        <table className="table table-bordered shadow">
          <thead>
            <tr>
              <th>S.N</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Role</th>
              <th>Date of Birth</th>
              <th>Salary</th>
              <th>SSN</th>
              <th>Employee ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.address}</td>
                  <td>{user.role}</td>
                  <td>{user.dateOfBirth}</td>
                  <td>{user.salary}</td>
                  <td>{user.ssn}</td>
                  <td>{user.employeeId}</td>
                  <td>
                    {/* Flexbox for aligning buttons horizontally on medium and large screens */}
                    <div className="d-flex flex-column flex-md-row justify-content-start">
                      <Link
                        className="btn btn-primary mx-1 mb-2 mb-md-0"
                        to={`/viewuser/${user.id}`}
                      >
                        View
                      </Link>
                      <Link
                        className="btn btn-outline-primary mx-1 mb-2 mb-md-0"
                        to={`/edituser/${user.id}`}
                      >
                        Edit
                      </Link>
                      <button
                        className="btn btn-danger mx-1"
                        onClick={() => openDeleteModal(user)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" className="text-center">
                  No employees found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Delete Confirmation Modal */}
      {currentEmployee && (
        <DeleteConfirmationModal
          show={showModal}
          handleClose={closeDeleteModal}
          handleDelete={handleDelete}
          itemId={currentEmployee.employeeId || "N/A"}
          itemName={currentEmployee.name}
          itemType="Employee"
        />
      )}
    </div>
  );
}
