import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import apiRequest from "../../lib/apiRequest";

export default function UpdateAdmin() {
  const navigate = useNavigate();
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const { auth, updateAuth } = useContext(AuthContext);
  const [admin, setAdmin] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "",
  });
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState("");
  const { name, email, phone, password } = admin;

  useEffect(() => {
    if (auth.user) {
      const fetchAdminData = async () => {
        try {
          const adminId = auth.user?.id;

          //console.log("admin id ", auth);

          if (!adminId) {
            throw new Error("Admin ID not found.");
          }

          const adminResponse = await apiRequest.get(`/admin/${adminId}`);
          const { name, email, phone, profileImage, role } = adminResponse.data;

          setAdmin({
            name,
            email,
            phone,
            password: "",
            role: role || "USER",
          });

          if (profileImage) {
            setProfileImage(profileImage);
          }
        } catch (error) {
          console.error(
            "Error fetching admin data:",
            error.response?.data || error.message
          );
          setError("Error fetching admin data. Please try again later.");
        }
      };

      fetchAdminData();
    }
  }, [auth.user]);

  const onInputChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const onImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  // Function to handle the entire update process including image upload
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      // Step 1: Upload the image if a new image is selected
      let imageUrl = null;
      if (profileImage instanceof File) {
        const imageFormData = new FormData();
        imageFormData.append("file", profileImage);

        // Log the content of imageFormData for debugging
        // for (let pair of imageFormData.entries()) {
        //   console.log(pair[0] + ": ", pair[1]);
        // }

        // Make the request to upload the image
        const imageResponse = await apiRequest.post(
          "/images/upload",
          imageFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Extract the image URL from the response if successful
        if (imageResponse.status === 200) {
          imageUrl = imageResponse.data; // Assuming the response contains the image URL as plain text or JSON
          //console.log("Image uploaded successfully. URL:", imageUrl);
        } else {
          throw new Error("Image upload failed");
        }
      }

      if (password && password !== reEnterPassword) {
        setPasswordMatchError(true);
        return;
      }

      // Step 2: Prepare the form data for updating admin details
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("role", admin.role);
      if (password) formData.append("password", password);
      if (imageUrl) formData.append("profileImageUrl", imageUrl); // Use the image URL from the upload response

      // Log the content of FormData for debugging
      // for (let pair of formData.entries()) {
      //   console.log(pair[0] + ": ", pair[1]);
      // }

      // console.log("formdata", formData);
      // Step 3: Make the request to update the admin details
      const response = await apiRequest.put(
        `/admin/update/${auth.user?.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle successful response
      if (response.status === 200) {
        const updatedAdminInfo = response.data;
        updateAuth(updatedAdminInfo, auth.token, auth.refreshToken);
        navigate("/");
      }
    } catch (error) {
      console.error(
        "Error during the update:",
        error.response?.data || error.message
      );
      setError("An error occurred during the update. Please try again.");
    }
  };

  const onReEnterPasswordChange = (e) => {
    const rePassword = e.target.value;
    setReEnterPassword(rePassword);
    setPasswordMatchError(password && rePassword && password !== rePassword);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-md-3 border rounded p-4 mt-2 shadow text-start">
          <h2 className="text-center m-4">Update Profile</h2>

          <div className="text-center mb-4">
            <img
              src={previewImage || profileImage || "/default.png"}
              alt="Profile"
              className="rounded-circle"
              width="150"
              height="150"
              style={{ objectFit: "cover" }}
            />
          </div>

          {error && <div className="alert alert-danger">{error}</div>}

          <form onSubmit={onSubmit}>
            <div className="mb-3">
              <label htmlFor="ProfileImage" className="form-label">
                Update Profile Image
              </label>
              <input
                type="file"
                className="form-control"
                onChange={onImageChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your name"
                name="name"
                value={name || ""}
                onChange={onInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter your email"
                name="email"
                value={email || ""}
                onChange={onInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Phone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your phone number"
                name="phone"
                value={phone || ""}
                onChange={onInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Password" className="form-label">
                Password (leave blank if not changing)
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter your password"
                name="password"
                value={password || ""}
                onChange={onInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="ReEnterPassword" className="form-label">
                Re-enter Password
              </label>
              <input
                type="password"
                className={`form-control ${
                  passwordMatchError ? "border-danger" : "border-success"
                }`}
                placeholder="Re-enter your password"
                value={reEnterPassword || ""}
                onChange={onReEnterPasswordChange}
              />
            </div>

            <button type="submit" className="btn btn-primary mx-2">
              Update
            </button>
            <Link className="btn btn-outline-danger mx-2" to="/">
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}
