import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import apiRequest from "../../lib/apiRequest";
import logger from "../../logger";

export default function AddSale() {
  const [sale, setSale] = useState({
    agentId: "",
    policyId: "",
    salesAmount: "",
    salesDate: "",
  });

  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  // Fetch employee list when component loads
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await apiRequest.get("/user/all");
        //const response = await axios.get("http://localhost:8080/users"); // Assuming this is the endpoint to fetch all users
        setEmployees(response.data);
      } catch (error) {
        logger.error("Error fetching employees", {
          message: error.message,
          stack: error.stack,
        });
      }
    };

    fetchEmployees();
  }, []);

  const handleChange = (e) => {
    setSale({ ...sale, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Log the data you're sending to the backend
      // console.log("Sending sale data to backend: ", {
      //   user: { id: sale.agentId },
      //   policyId: sale.policyId,
      //   salesAmount: sale.salesAmount,
      //   salesDate: sale.salesDate,
      // });

      // Send the request
      await apiRequest.post("/sales/add", {
        user: {
          id: sale.agentId,
        },
        policyId: sale.policyId,
        salesAmount: sale.salesAmount,
        salesDate: sale.salesDate,
      });

      // Check if the request was successful
      //console.log("Sale added successfully, response: ", response.data);

      // Redirect to the sales list page after adding
      navigate("/sale-list");
    } catch (error) {
      // Log any errors that occur
      //console.error("Error adding sale: ", error);

      // Check if it's a network error
      if (error.response) {
        // console.log(
        //   "Server responded with a status code:",
        //   error.response.status
        // );
        //console.log("Response data:", error.response.data);
      } else if (error.request) {
        //console.log("No response received from server:", error.request);
      } else {
        //console.log("Error setting up the request:", error.message);
      }
    }
  };

  return (
    <div className="col-md-8 offset-md-2 border rounded p-4 mt-2 shadow text-start">
      <h2 className="text-center">Add Sale</h2>
      <form onSubmit={handleSubmit}>
        {/* Agent Selection */}
        <div className="form-group">
          <label>Agent</label>
          <select
            className="form-control"
            name="agentId"
            value={sale.agentId}
            onChange={handleChange}
            required
          >
            <option value="">Select Agent</option>
            {employees.map((employee) => (
              <option key={employee.id} value={employee.id}>
                {employee.name} (ID: {employee.employeeId})
              </option>
            ))}
          </select>
        </div>

        {/* Policy ID */}
        <div className="form-group">
          <label>Policy ID</label>
          <input
            type="text"
            className="form-control"
            name="policyId"
            value={sale.policyId}
            onChange={handleChange}
            required
          />
        </div>

        {/* Sales Amount */}
        <div className="form-group">
          <label>Sales Amount</label>
          <input
            type="number"
            className="form-control"
            name="salesAmount"
            value={sale.salesAmount}
            onChange={handleChange}
            required
          />
        </div>

        {/* Sales Date */}
        <div className="form-group">
          <label>Sales Date</label>
          <input
            type="date"
            className="form-control"
            name="salesDate"
            value={sale.salesDate}
            onChange={handleChange}
            required
          />
        </div>
        {/* Buttons in the same row */}
        <div className="d-flex justify-content-start mt-3">
          <button type="submit" className="btn btn-primary">
            Add Sale
          </button>
          <Link className="btn btn-outline-danger mx-2" to="/sale">
            Cancel
          </Link>
        </div>
      </form>
    </div>
  );
}
