import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import apiRequest from "../../lib/apiRequest";
export default function EditPayroll() {
  let navigate = useNavigate();
  const { id } = useParams();

  const [payroll, setPayroll] = useState({
    baseSalary: "",
    bonus: "",
    deductions: "",
    payrollDate: "",
    employeeId: "",
    user: { employeeId: "" },
  });

  const { baseSalary, bonus, deductions, payrollDate, employeeId } = payroll;
  //console.log("payroll", employeeId);

  const onInputChange = (e) => {
    setPayroll({ ...payroll, [e.target.name]: e.target.value || "" });
  };

  // Memoize loadPayroll function to avoid ESLint dependency warning
  const loadPayroll = useCallback(async () => {
    const result = await apiRequest.get(`/payroll/${id}`);
    //setPayroll(result.data);
    setPayroll({
      ...result.data,
      employeeId: result.data.user?.employeeId || "", // Map employeeId to agentId
    });
  }, [id]);

  useEffect(() => {
    loadPayroll();
  }, [loadPayroll]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await apiRequest.put(`/payroll/update/${id}`, payroll);
    navigate("/payroll-list");
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2 border rounded p-4 mt-2 text-start shadow ">
          <h2 className="text-center m-4">Edit Payroll</h2>

          <form onSubmit={(e) => onSubmit(e)}>
            <div className="mb-3">
              <label htmlFor="EmployeeID" className="form-label">
                Employee ID
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Employee ID"
                name="employeeId"
                value={employeeId || ""} // Ensure value is always a string
                onChange={(e) => onInputChange(e)}
                readOnly // Employee ID should not be editable
              />
            </div>
            <div className="mb-3">
              <label htmlFor="BaseSalary" className="form-label">
                Base Salary
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Base Salary"
                name="baseSalary"
                value={baseSalary || ""} // Ensure value is always a string
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Bonus" className="form-label">
                Bonus
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Bonus"
                name="bonus"
                value={bonus || ""} // Ensure value is always a string
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Deductions" className="form-label">
                Deductions
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Deductions"
                name="deductions"
                value={deductions || ""} // Ensure value is always a string
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="PayrollDate" className="form-label">
                Payroll Date
              </label>
              <input
                type="date"
                className="form-control"
                name="payrollDate"
                value={payrollDate || ""} // Ensure value is always a string
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <button type="submit" className="btn btn-outline-primary">
              Submit
            </button>
            <Link className="btn btn-outline-danger mx-2" to="/payroll-list">
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}
