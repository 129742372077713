import log from 'loglevel';

// Set the log level based on the environment
if (process.env.NODE_ENV === 'production') {
    log.setLevel('warn');  // Only show warnings and errors in production
} else {
    log.setLevel('debug'); // Show everything (debug, info, warn, error) in development
}

export default log;


