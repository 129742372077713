import "./home.css"; // For styling the splash screen
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div className="home-screen  ">
      <div className="container mb-50px">
        <h1>Welcome to KC Management</h1>
        <h2 className=" mt-3 text-center">Admin Center</h2>
        <div className="py-3 d-flex justify-content-center gap-3">
          <Link className="btn btn-outline-primary" to="/employee">
            👥 Employee
          </Link>
          <Link className="btn btn-outline-primary" to="/sale">
            📊 Sales
          </Link>
          <Link className="btn btn-outline-primary" to="/payroll">
            💰 Payroll
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
