import { Link } from "react-router-dom";
import PayrollDashboard from "../../component/payroll/payrollDashboard";

export default function PayrollPage() {
  return (
    <div className="container">
      <h2 className="text-center my-3">Payroll Center</h2>
      <div className="py-4 d-flex justify-content-center gap-3">
        <Link className="btn btn-outline-primary" to="/addPayroll">
          + Add Payroll
        </Link>
        <Link className="btn btn-outline-primary" to="/payroll-list">
          🔎 View Payroll
        </Link>
      </div>
      <PayrollDashboard />
    </div>
  );
}
