import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import apiRequest from "../../lib/apiRequest";

export default function RegisterAdmin() {
  let navigate = useNavigate();

  const [admin, setAdmin] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });

  const [reEnterPassword, setReEnterPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false); // State for live password match feedback

  const { name, email, phone, password } = admin;

  const onInputChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const onReEnterPasswordChange = (e) => {
    const rePassword = e.target.value;
    setReEnterPassword(rePassword);

    // Check password match and update passwordMatchError
    if (password && rePassword && password !== rePassword) {
      setPasswordMatchError(true); // Passwords don't match
    } else if (password && rePassword && password === rePassword) {
      setPasswordMatchError(false); // Passwords match
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Final check if passwords match before form submission
    if (password !== reEnterPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Create JSON object for registration
    const formData = {
      name,
      email,
      phone,
      password,
    };

    try {
      console.log("payload to register admin", formData);
      const response = await apiRequest.post(
        "/admin/register-admin",
        formData, // Send the data as JSON
        {
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      );

      //console.log("this is register response", response);

      if (response.status === 201) {
        navigate("/login"); // Redirect after successful registration
      }
    } catch (error) {
      // Log the error object for detailed debugging
      console.error("Registration error:", error);
      setError("An error occurred during registration. Please try again.");
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-md-3 border rounded p-4 mt-2 shadow text-start">
          <h2 className="text-center m-4">Admin Registration</h2>

          {error && <div className="alert alert-danger">{error}</div>}

          <form onSubmit={onSubmit}>
            <div className="mb-3">
              <label htmlFor="Name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your name"
                name="name"
                value={name}
                onChange={onInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter your email"
                name="email"
                value={email}
                onChange={onInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Phone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your phone number"
                name="phone"
                value={phone}
                onChange={onInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter your password"
                name="password"
                value={password}
                onChange={onInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="ReEnterPassword" className="form-label">
                Re-enter Password
              </label>
              <input
                type="password"
                className={`form-control ${
                  passwordMatchError ? "border-danger" : "border-success"
                }`}
                placeholder="Re-enter your password"
                value={reEnterPassword}
                onChange={onReEnterPasswordChange}
                style={{
                  borderColor: passwordMatchError
                    ? "red"
                    : reEnterPassword && !passwordMatchError
                    ? ""
                    : "",
                }}
              />
            </div>

            <button type="submit" className="btn btn-primary mx-2">
              Register
            </button>
            <Link className="btn btn-outline-danger mx-2" to="/login">
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}
