import { Modal, Button } from "react-bootstrap";
import "./AdminAccessModal.css";

export default function AdminAccessModal({ show, handleClose }) {
  return (
    <div className="modal-backdrop-custom">
      {" "}
      {/* Background container */}
      <Modal show={show} onHide={handleClose} centered className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Access Restricted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="text-center ">Welcome To KC Management 🎊</h3>
          <p className="text-center">
            Unfortunatley, Only users with an Admin role are allowed to access
            this page.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
