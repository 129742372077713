import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import apiRequest from "../../lib/apiRequest"; // API helper
import AdminEditModal from "../modals/AdminEditModal"; // Import the Modal Component
import "./admin.css"; // Styling for the admin cards

export default function AdminPage() {
  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null); // To track the selected admin for editing
  const [showEditModal, setShowEditModal] = useState(false); // Control modal visibility

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await apiRequest.get("/admin/admins");
        setAdmins(response.data);
      } catch (error) {
        console.error("Error fetching admins:", error.message);
      }
    };
    fetchAdmins();
  }, []);

  const handleEdit = (admin) => {
    setSelectedAdmin(admin); // Set the admin to be edited
    setShowEditModal(true); // Open the modal
  };

  const handleDelete = async (adminId) => {
    //console.log("admin id ", adminId);
    try {
      await apiRequest.delete(`/admin/delete/${adminId}`);
      setAdmins(admins.filter((admin) => admin.id !== adminId)); // Update admin list
    } catch (error) {
      console.error("Error deleting admin:", error.message);
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center">Admin Management</h2>

      <div className="row">
        {admins.map((admin) => (
          <div className="col-md-4 mb-4" key={admin.id}>
            <div className="card shadow-sm">
              <div className="card-body text-center">
                <img
                  src={admin.profileImage || "/default.png"}
                  alt="Admin Profile"
                  className="rounded-circle admin-card-image"
                />
                <h5 className="card-title mt-3">{admin.name}</h5>
                <p className="card-text">
                  <strong>Email:</strong> {admin.email}
                </p>
                <p className="card-text">
                  <strong>Phone:</strong> {admin.phone}
                </p>
                <p className="card-text">
                  <strong>Role:</strong> {admin.role}
                </p>
                <div className="mt-3">
                  <Button
                    className="btn btn-primary mx-2"
                    onClick={() => handleEdit(admin)}
                  >
                    Edit
                  </Button>
                  <Button
                    className="btn btn-danger mx-2"
                    onClick={() => handleDelete(admin.id)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Admin Edit Modal */}
      <AdminEditModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        admin={selectedAdmin}
        setAdmins={setAdmins}
        admins={admins}
      />
    </div>
  );
}
