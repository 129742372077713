import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import "chart.js/auto"; // Automatically register necessary chart components
import apiRequest from "../../lib/apiRequest";
import logger from "../../logger";

export default function SalesDashboard() {
  const [sales, setSales] = useState([]);
  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const [avgSalesAmount, setAvgSalesAmount] = useState(0);
  const [totalSales, setTotalSales] = useState(0);

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await apiRequest.get("/sales/all");

        //const response = await axios.get("http://localhost:8080/sales/all");
        const salesData = response.data;

        // Calculate total sales amount, average sales amount, and number of sales
        const totalAmount = salesData.reduce(
          (acc, sale) => acc + sale.salesAmount,
          0
        );
        setTotalSalesAmount(totalAmount);
        setAvgSalesAmount(totalAmount / salesData.length);
        setTotalSales(salesData.length);
        setSales(salesData);
      } catch (error) {
        logger.error("Error fetching sales data", {
          message: error.message,
          stack: error.stack,
        });

        //console.error("Error fetching sales data:", error);
      }
    };

    fetchSales();
  }, []);

  // Data for sales over time (using Line chart)
  const salesTimelineData = {
    labels: sales.map((sale) => new Date(sale.salesDate).toLocaleDateString()),
    datasets: [
      {
        label: "Sales Amount Over Time",
        data: sales.map((sale) => sale.salesAmount),
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: true,
      },
    ],
  };

  // Data for sales amount distribution by agent (using Bar chart)
  const salesByAgentData = {
    labels: sales.map((sale) => sale.agentId),
    datasets: [
      {
        label: "Sales Amount by Agent",
        data: sales.map((sale) => sale.salesAmount),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  return (
    <div className="container my-3">
      <h2 className="text-center mb-4">Sales Dashboard</h2>

      {/* Top indicators for total sales, average sales amount, and total sales */}
      <div className="row text-center">
        <div className="col-md-4 mt-3 ">
          <div className="card bg-primary text-white shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Total Sales Amount</h5>
              <p className="card-text display-4">
                ${totalSalesAmount.toLocaleString()}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <div className="card bg-success text-white shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Average Sales Amount</h5>
              <p className="card-text display-4">
                ${avgSalesAmount.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <div className="card bg-warning text-white shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Total Sales</h5>
              <p className="card-text display-4">{totalSales}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Sales by Agent Chart and Sales Over Time Chart */}
      <div className="row mt-5">
        <div className="col-md-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="text-center">Sales by Agent (Chart)</h5>
              <Bar data={salesByAgentData} />
            </div>
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="text-center">Sales Over Time (Chart)</h5>
              <Line data={salesTimelineData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
