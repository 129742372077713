import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import apiRequest from "../../lib/apiRequest";

export default function AddPayroll() {
  const [payroll, setPayroll] = useState({
    employeeId: "",
    baseSalary: "",
    bonus: "",
    deductions: "",
    payrollDate: "",
  });

  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();

  // Fetch employee list when component loads
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await apiRequest.get("/user/all");
        //const response = await axios.get("http://localhost:8080/users"); // Assuming this is the endpoint to fetch all users
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  const handleChange = (e) => {
    setPayroll({ ...payroll, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiRequest.post("/payroll/add", {
        user: { id: payroll.employeeId }, // Use employee ID
        baseSalary: payroll.baseSalary,
        bonus: payroll.bonus,
        deductions: payroll.deductions,
        payrollDate: payroll.payrollDate,
      });
      console.log("Payroll added:", response.data);
      navigate("/payroll-list"); // Redirect after successful submission
    } catch (error) {
      console.error("Error adding payroll:", error);
    }
  };

  return (
    <div className="col-md-8 offset-md-2 border rounded p-4 mt-2 shadow text-start">
      <h2 className="text-center">Add Payroll</h2>
      <form onSubmit={handleSubmit}>
        {/* Employee Selection */}
        <div className="form-group">
          <label>Employee</label>
          <select
            className="form-control"
            name="employeeId"
            value={payroll.employeeId}
            onChange={handleChange}
            required
          >
            <option value="">Select Employee</option>
            {employees.map((employee) => (
              <option key={employee.id} value={employee.id}>
                {employee.name} (ID: {employee.employeeId})
              </option>
            ))}
          </select>
        </div>

        {/* Base Salary */}
        <div className="form-group">
          <label>Base Salary</label>
          <input
            type="number"
            className="form-control"
            name="baseSalary"
            value={payroll.baseSalary}
            onChange={handleChange}
            required
          />
        </div>

        {/* Bonus */}
        <div className="form-group">
          <label>Bonus</label>
          <input
            type="number"
            className="form-control"
            name="bonus"
            value={payroll.bonus}
            onChange={handleChange}
            required
          />
        </div>

        {/* Deductions */}
        <div className="form-group">
          <label>Deductions</label>
          <input
            type="number"
            className="form-control"
            name="deductions"
            value={payroll.deductions}
            onChange={handleChange}
            required
          />
        </div>

        {/* Payroll Date */}
        <div className="form-group">
          <label>Payroll Date</label>
          <input
            type="date"
            className="form-control"
            name="payrollDate"
            value={payroll.payrollDate}
            onChange={handleChange}
            required
          />
        </div>

        {/* Buttons in the same row */}
        <div className="d-flex justify-content-start mt-3">
          <button type="submit" className="btn btn-primary">
            Add Payroll
          </button>
          <Link className="btn btn-outline-danger mx-2" to="/payroll">
            Cancel
          </Link>
        </div>
      </form>
    </div>
  );
}
