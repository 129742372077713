import React, { useEffect, useState } from "react";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import "bootstrap/dist/css/bootstrap.min.css";
import apiRequest from "../../lib/apiRequest";

export default function PayrollDashboard() {
  const [payrolls, setPayrolls] = useState([]);
  const [totalSalary, setTotalSalary] = useState(0);
  const [avgSalary, setAvgSalary] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);

  useEffect(() => {
    const fetchPayrolls = async () => {
      const response = await apiRequest.get("/payroll/all");
      const payrollData = response.data;

      // Compute the total salary, average salary, and number of employees
      const totalSalaries = payrollData.reduce((acc, payroll) => {
        return acc + payroll.baseSalary + payroll.bonus - payroll.deductions;
      }, 0);

      setTotalSalary(totalSalaries);
      setAvgSalary(totalSalaries / payrollData.length);
      setTotalEmployees(payrollData.length);
      setPayrolls(payrollData);
    };

    fetchPayrolls();
  }, []);

  const salaryChartData = {
    labels: payrolls.map((payroll) =>
      payroll.user ? payroll.user.name : "Unknown"
    ), // Add a null check for user
    datasets: [
      {
        label: "Total Salary",
        data: payrolls.map(
          (payroll) => payroll.baseSalary + payroll.bonus - payroll.deductions
        ),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  // Data for Payroll Date (using Line chart)
  const payrollTimelineData = {
    labels: payrolls.map((payroll) => payroll.payrollDate),
    datasets: [
      {
        label: "Total Salary Over Time",
        data: payrolls.map(
          (payroll) => payroll.baseSalary + payroll.bonus - payroll.deductions
        ),
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: true,
      },
    ],
  };

  return (
    <div className="container my-3">
      <h2 className="text-center mb-4">Payroll Dashboard</h2>

      {/* Top indicators for total salary, avg salary, and total employees */}
      <div className="row text-center">
        <div className="col-md-4 mt-3">
          <div className="card bg-primary text-white">
            <div className="card-body">
              <h5 className="card-title">Total Salary Paid</h5>
              <p className="card-text">${totalSalary.toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <div className="card bg-success text-white">
            <div className="card-body">
              <h5 className="card-title">Average Salary</h5>
              <p className="card-text">${avgSalary.toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <div className="card bg-warning text-white">
            <div className="card-body">
              <h5 className="card-title">Total Employees</h5>
              <p className="card-text">{totalEmployees}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Chart Section */}
      <div className="row mt-5">
        <div className="col-md-6">
          <h4 className="text-center">Salary Distribution</h4>
          <Bar data={salaryChartData} />
        </div>
        <div className="col-md-6 mt-5">
          <h4 className="text-center">Salary Over Time</h4>
          <Line data={payrollTimelineData} />
        </div>
      </div>

      {/* Doughnut Chart for Salary Breakdown */}
      <div className="row mt-5 justify-content-center">
        <div className="col-md-6">
          <h4 className="text-center">Salary Breakdown</h4>
          <Doughnut
            data={{
              labels: ["Base Salary", "Bonus", "Deductions"],
              datasets: [
                {
                  label: "Salary Breakdown",
                  data: [
                    payrolls.reduce((acc, p) => acc + p.baseSalary, 0),
                    payrolls.reduce((acc, p) => acc + p.bonus, 0),
                    payrolls.reduce((acc, p) => acc + p.deductions, 0),
                  ],
                  backgroundColor: ["#007bff", "#28a745", "#ffc107"],
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
}
