
import axios from "axios";
import Cookies from "js-cookie";
//import config from "../../src/config/config";

// Log the URL value from environment variables
//console.log('API Base URL:', process.env.REACT_APP_API_URL);


// Create an Axios instance
const apiRequest = axios.create({
    // baseURL: "http://localhost:8080",
    // baseURL: `${process.env.REACT_APP_API_URL}`,
    //baseURL: `${config.apiBaseURL}/api`,
    baseURL: "https://api.optimizeservice.net/api",
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
    withCredentials: true, // Ensure cookies are sent with the request
});


apiRequest.interceptors.request.use(
    (config) => {
        // Retrieve the accesstoken initially 
        const token = Cookies.get("accessToken"); // Retrieve the JWT token from cookies

        //console.log("initial token", token)

        if (token) {
            config.headers.Authorization = `Bearer ${token}`; // Attach token to Authorization header
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);




apiRequest.interceptors.response.use(
    (response) => {
        //console.log("Interceptor successful response when login:", response);
        return response;
    },
    async (error) => {


        const originalRequest = error.config;

        // Log the original request to debug what was being sent
        //console.log("Original request that caused the error:", originalRequest);

        // Log the error details to understand why the request failed
        // console.log("Error details:", error);

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            //console.log("401 Unauthorized detected. Attempting token refresh...");
            originalRequest._retry = true;

            try {
                const refreshToken = Cookies.get("refreshToken");
                //console.log("Refresh Token from Cookie:", refreshToken); // Add this log before checking

                // Ensure refresh token exists
                if (!refreshToken) {
                    //console.error("No refresh token available."); // This should log if refreshToken is null
                    throw new Error("No refresh token available");
                }

                // Attempt to refresh the access token
                const refreshResponse = await axios.post(
                    // "http://localhost:8080/auth/refresh",
                    "https://api.optimizeservice.net/api/auth/refresh",
                    null,
                    {
                        withCredentials: true,
                    }
                );

                const newAccessToken = refreshResponse.data.newAccessToken;

                // Store the new access token in cookies
                Cookies.set("accessToken", newAccessToken, { expires: 7, secure: true, sameSite: "Strict" });

                // Double-check if the cookie is set correctly
                //const storedToken = Cookies.get("accessToken");
                //console.log("New accessToken saved in cookies:", storedToken);
                //console.log(" 2nd Refresh Token from Cookie:", refreshToken);



                // Attach the new token to the original request and retry it
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                // console.log("Retrying with new token:", newAccessToken);
                // console.log("Headers for retry request:", originalRequest.headers);
                return apiRequest(originalRequest);
            } catch (refreshError) {
                console.error("Token refresh failed:", refreshError);

                // Handle refresh token failure (log out user)
                Cookies.remove("accessToken");
                Cookies.remove("refreshToken");
                Cookies.remove("user");
                //console.log("Redirecting to login due to refresh token failure.");
                window.location.href = "/payroll";
            }
        }

        return Promise.reject(error);
    }
);


export default apiRequest;
