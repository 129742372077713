import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  FaUser,
  FaCog,
  FaSignOutAlt,
  FaThLarge,
  FaEllipsisV,
  FaMoneyBill,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import "./sidebar.css";
import Cookies from "js-cookie";
import { AuthContext } from "../../context/AuthContext"; // Import AuthContext
import { FaUserShield } from "react-icons/fa6";

export default function Sidebar() {
  const [isOpen, setIsOpen] = useState(false); // Track sidebar open/close state
  const sidebarRef = useRef(null); // Reference to the sidebar
  const toggleBtnRef = useRef(null); // Reference to the toggle button
  const { auth, updateAuth } = useContext(AuthContext); // Get auth and logout from context

  //console.log("sidebar", auth.user);
  const navigate = useNavigate();
  const location = useLocation(); // Detect route changes

  // Close the sidebar when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Ensure that the click is not on the sidebar or the toggle button
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        toggleBtnRef.current &&
        !toggleBtnRef.current.contains(event.target)
      ) {
        setIsOpen(false); // Close sidebar when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Close sidebar on route change
  useEffect(() => {
    setIsOpen(false); // Close sidebar when navigating
  }, [location]);

  // Toggle sidebar open/close
  const toggleSidebar = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleLogout = () => {
    updateAuth({ auth: null, token: null });
    Cookies.remove("user");
    Cookies.remove("token");
    navigate("/"); // Redirect to home page after logout
  };

  return (
    <div className="sidebarContainer">
      {/* Toggle Button for opening/closing sidebar */}
      <button
        className="toggle-btn fixed-toggle-btn"
        onClick={toggleSidebar}
        ref={toggleBtnRef} // Reference the toggle button
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaEllipsisV style={{ marginRight: "5px" }} />
          <FaThLarge />
        </div>
      </button>

      {/* Sidebar */}
      <div ref={sidebarRef} className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="sidebar-header">
          <h2 className="logo">KC Management</h2>
        </div>

        <nav className="menu bg-dark p-3">
          <Link
            to="/employee"
            className="menu-item d-flex align-items-center text-light py-2"
          >
            <FaUser className="menu-icon me-2" /> Employee
          </Link>
          <Link
            to="/sale"
            className="menu-item d-flex align-items-center text-light py-2"
          >
            <FaFileInvoiceDollar className="menu-icon me-2" /> Sales
          </Link>
          <Link
            to="/payroll"
            className="menu-item d-flex align-items-center text-light py-2"
          >
            <FaMoneyBill className="menu-icon me-2" /> Payroll
          </Link>

          <Link
            to="/admin"
            className="menu-item d-flex align-items-center text-light py-2"
          >
            <FaUserShield className="menu-icon me-2" /> Admin
          </Link>
          <Link
            to="/upcoming-feature"
            className="menu-item d-flex align-items-center text-light py-2"
          >
            <FaCog className="menu-icon me-2" /> Settings
          </Link>

          {/* Logout */}
          <div
            className="menu-item d-flex align-items-center text-light py-2"
            onClick={handleLogout}
            style={{ cursor: "pointer" }}
          >
            <FaSignOutAlt className="menu-icon me-2" /> Logout
          </div>
        </nav>

        <Link to="/update-admin" className="profile-section cursor-pointer">
          {/* Display profile image and name from context */}
          <img
            src={
              auth.user?.profileImage || `${process.env.PUBLIC_URL}/default.png`
            }
            alt="Profile"
            className="profile-pic"
          />
          <p className="profile-name">{auth.user?.name || "Admin"}</p>
        </Link>
      </div>
    </div>
  );
}
