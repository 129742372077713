import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function DeleteConfirmationModal({
  show,
  handleClose,
  handleDelete,
  itemId,
  itemName,
  itemType,
}) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {`Are you sure you want to delete ${itemType} with ID: ${itemId} and Name: ${itemName}?`}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleDelete(itemId)}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
