import { Link } from "react-router-dom";
import UserDashboard from "../../component/users/userDashboard";

export default function EmployeePage() {
  return (
    <div className="container">
      <h2 className="text-center my-3">Employee Center</h2>
      <div className="py-4 d-flex justify-content-center gap-3">
        <Link className="btn btn-outline-primary" to="/addEmployee">
          + Add Employee
        </Link>
        <Link className="btn btn-outline-primary" to="/employeeList">
          🔎 View Employee
        </Link>
      </div>
      <UserDashboard />
    </div>
  );
}
