import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import logger from "../logger";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    token: null,
    refreshToken: null, // Add refreshToken to state
  });

  // Utility function to load user data, token, and refreshToken from cookies
  const loadFromCookies = () => {
    const userCookie = Cookies.get("user");
    const tokenCookie = Cookies.get("accessToken");
    const refreshTokenCookie = Cookies.get("refreshToken"); // Get refresh token

    // console.log("User  authContext:", userCookie);
    // console.log("Access token authContext Cookie:", tokenCookie);
    // console.log("Refresh token authContext Cookie:", refreshTokenCookie);

    try {
      if (userCookie && tokenCookie && refreshTokenCookie) {
        const user = JSON.parse(userCookie);
        setAuth({
          user,
          token: tokenCookie,
          refreshToken: refreshTokenCookie, // Set refresh token
        });
      } else {
        setAuth({
          user: null,
          token: null,
          refreshToken: null, // Set refresh token to null if not found
        });
      }
    } catch (error) {
      logger.error("Error fetching sales data", {
        message: error.message,
        stack: error.stack,
      });

      //console.error("Failed to parse 'user' cookie:", error);
      setAuth({
        user: null,
        token: null,
        refreshToken: null,
      });
    }
  };

  useEffect(() => {
    loadFromCookies(); // Load from cookies on initial load or refresh
  }, []);

  // Function to update auth state and set cookies
  const updateAuth = (user, accessToken, refreshToken) => {
    setAuth({ user, token: accessToken, refreshToken });
    //console.log("updated user", user);
    Cookies.set("user", JSON.stringify(user), {
      expires: 7,
      secure: true,
      sameSite: "Strict",
    });
    Cookies.set("accessToken", accessToken, {
      expires: 7,
      secure: true,
      sameSite: "Strict",
    });
    Cookies.set("refreshToken", refreshToken, {
      expires: 7,
      secure: true,
      sameSite: "Strict",
    });
  };

  // console.log("Access Token from Cookie:", token);
  // Function to check if refresh token is available
  const hasRefreshToken = () => {
    return !!auth.refreshToken; // Check if refreshToken exists in state
  };

  // Function to clear auth state and remove cookies (for logout)
  const logout = () => {
    setAuth({
      user: null,
      token: null,
      refreshToken: null, // Clear refresh token
    });
    Cookies.remove("user");
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken"); // Remove refresh token cookie
  };

  return (
    <AuthContext.Provider value={{ auth, updateAuth, logout, hasRefreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};
