import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import apiRequest from "../../lib/apiRequest";
//import config from "../../../src/config/config";
import Cookies from "js-cookie";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google"; // Import Google login

export default function AdminLogin() {
  // const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const clientId =
    "96511326298-uompsf1she2ijf255rfqhpk56enb7orc.apps.googleusercontent.com";

  //   config.googleClientID ||
  //   "96511326298-uompsf1she2ijf255rfqhpk56enb7orc.apps.googleusercontent.com";

  const navigate = useNavigate();
  const { updateAuth } = useContext(AuthContext); // Access the AuthContext
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");

  // Utility function to set access and refresh tokens along with user info in cookies
  const setAuthCookies = (accessToken, refreshToken, user) => {
    // Store access token in cookies
    Cookies.set("accessToken", accessToken, {
      expires: 7, // Access token expires after 7 days (or adjust based on your token's expiration)
      secure: true,
      sameSite: "Strict",
    });
    // Store refresh token in cookies
    Cookies.set("refreshToken", refreshToken, {
      expires: 7, // Refresh token expiry should match its expiration in the backend
      secure: true,
      sameSite: "Strict",
    });
    //Store user info in cookies
    Cookies.set("user", JSON.stringify(user), {
      expires: 7,
      secure: true,
      sameSite: "Strict",
    });
  };

  // Handle input change
  const onInputChange = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validate that email and password are not empty
    if (!loginDetails.email || !loginDetails.password) {
      setError("Email and password are required");
      return; // Stop the submission if fields are empty
    }

    try {
      // Send login request to backend
      const response = await apiRequest.post("/auth/login", loginDetails);
      //console.log("this is regular login", response.data);

      if (response.status === 200) {
        // Extract the tokens and user data from response
        const { accessToken, refreshToken, user } = response.data;

        // Store tokens and user in cookies
        setAuthCookies(accessToken, refreshToken, user);

        // Update the AuthContext with user and tokens
        updateAuth(user, accessToken, refreshToken);

        // Redirect to homepage or any other protected route
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        setError("Invalid credentials");
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  // Handle Google login success
  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const googleToken = credentialResponse.credential;
      //console.log("googleToken", googleToken);

      // Send the Google token to your backend
      const response = await apiRequest.post("/auth/google", {
        token: googleToken,
      });

      //console.log("Google login ", response.data);

      if (response.status === 200) {
        //console.log("google login", response.data);
        // Extract the tokens and user data from response
        const { accessToken, refreshToken, user } = response.data;
        setAuthCookies(accessToken, refreshToken, user);
        updateAuth(user, accessToken, refreshToken);

        // Redirect to homepage or any other protected route
        navigate("/");
      }
    } catch (error) {
      setError("Google sign-in failed. Please try again.");
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div className="row w-100">
        <div className="col-md-6 offset-md-3 border rounded p-4 mb-5 shadow text-start">
          <h2 className="text-center m-3 font-weight-bold h4">Welcome</h2>
          <p className="text-center">
            Log into your account using social buttons or create a new one!
          </p>

          {/* Google Login */}
          <div className="p-3 d-flex justify-content-center gap-3 w-100 google-login-button">
            <GoogleOAuthProvider clientId={clientId}>
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={() => setError("Google login failed")}
              />
            </GoogleOAuthProvider>
          </div>

          <div className="d-flex align-items-center justify-content-center w-100 mt-3">
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#ddd",
              }}
            ></div>
            <span className="px-3">or</span>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#ddd",
              }}
            ></div>
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
          {/* Traditional Login Form */}
          <form onSubmit={onSubmit}>
            <div className="mb-3">
              <label htmlFor="Email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={loginDetails.email}
                onChange={onInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={loginDetails.password}
                onChange={onInputChange}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary w-100">
              Login
            </button>
          </form>
          <div className="text-center mt-3">
            <Link to="/register-admin" className="btn btn-link">
              Create an Account
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
